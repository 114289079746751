
.container {
  background-image: url('https://media.discordapp.net/attachments/1154813877448617989/1154820345086365706/UOR_DiagonAlley_VB.jpg?width=1558&height=790');
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.medieval-title {
  font-family: 'MedievalFont', serif;
  font-size: 64px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.medieval-subtitle {
  font-family: 'MedievalFont', serif; 
  font-size: 25px; 
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.medieval-subtitle_2 {
  font-family: 'MedievalFont', serif; 
  font-size: 25px; 
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #615b82;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.medieval-subtitle_question {
  font-family: 'MedievalFont', serif; 
  font-size: 14px; 
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #f4f4f4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}



.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.my-list {
  background: rgba(163, 162, 162, 0.5);
  padding: 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 600px;
}

.my-list li {
  font-family: 'MedievalFont', sans-serif; 
  font-size: 18px; 
  font-weight: bold; 
  color: #000; 
  list-style-type: none;
}


input {
  width: 100%;
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
}

.chat-input{
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
}



button {
  padding: 20px 50px;
  background-color: #252426;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 22px;
  font-family: 'MedievalSharp', cursive;
}



button:hover {
  background-color: #0056b3; 
}



.alfa_question_container {
  background-color: white;
  display: grid;
  grid-template-columns: auto 1fr; 
  grid-template-rows: auto auto auto 1fr; 
  gap: 20px; 
  padding: 20px; 
  border-radius: 15px;
}


.trivia-title {
  grid-column: 1 / span 1; 
  grid-row: 1 / span 1; 
  text-align: left;
}


.streak-container {
  grid-column: 2 / span 1; 
  grid-row: 1 / span 1; 
  text-align: right;
}


.question-container {
  grid-column: 1 / span 1; 
  grid-row: 3 / span 1; 
  text-align: left;
  background-color: #615b82; 
  border-radius: 10px; 
}


.question-container .medieval-subtitle {
  text-align: center;
}


.ranking-container {
  grid-column: 2 / span 1; 
  grid-row: 2 / span 2; 
  text-align: left;
  padding: 20px;
  background-color: #615b82; 
  border-radius: 10px; 
}


.ranking-list {
  list-style: none; 
  padding: 0;
  margin: 0;
}


.ranking-list li {
  margin: 5px 0; 
}

.options-container {
  grid-column: 1 / span 1; 
  grid-row: 3 / span 1; 
  text-align: left;
  padding: 20px;
  background-color: #615b82; 
  border-radius: 10px; 
}

.option-button {
  padding: 10px 20px;
  background-color: #252426;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 15px;
  font-family: 'MedievalSharp', cursive;
  margin-bottom: 10px;
}


.option-button:disabled {
  padding: 10px 20px;
  background-color: #ccc; 
  color: #666; 
  border: none;
  border-radius: 5px;
  cursor: not-allowed; 
  margin-left: 10px;
  font-size: 15px;
  font-family: 'MedievalSharp', cursive;
  margin-bottom: 10px;
}


.end-container {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1; 
  text-align: left;
  padding: 20px;
  background-color: #615b82; 
  border-radius: 10px; 
}
